import React from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import BlockIcon from "@mui/icons-material/Block";
import {withStyles} from "tss-react/mui";
import Icon from "@mui/material/Icon";

const defaultToolbarSelectStyles = {
    iconButton: {},
    iconContainer: {
        marginRight: "24px",
    },
    inverseIcon: {
        transform: "rotate(90deg)",
    },
};

const CustomToolbarSelect = ({classes, displayData, selectedRows, canEdit = true, canDelete = true, setSelectedRows, handleEditClick, handleDeleteClick}) => {

    const formatDeleteData = (rows, data) => {

        let ids = [];

        for(let i = 0; i < rows.data.length; i++){
            ids.push(data[rows.data[i].index].data[0]);
        }

        return ids;
    };

    return (
        <div className={classes.iconContainer}>
            {
                canEdit &&
                <Tooltip title={"Edit Record"}>
                    <IconButton disabled={selectedRows.data.length !== 1} className={classes.iconButton} onClick={() => handleEditClick(displayData[selectedRows.data[0].index].data[0])}>
                        <Icon>edit</Icon>
                    </IconButton>
                </Tooltip>
            }
            {
                canDelete &&
                <Tooltip title={"Deactivate or Activate Record"}>
                    <IconButton className={classes.iconButton} onClick={() => handleDeleteClick(formatDeleteData(selectedRows, displayData))}>
                        <Icon>delete</Icon>
                    </IconButton>
                </Tooltip>
            }
        </div>
    );
};

export default withStyles(CustomToolbarSelect, defaultToolbarSelectStyles, {name: "CustomToolbarSelect"});
