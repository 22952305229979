/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { current_password, password, password_confirmation },
} = checkout;

const validations = Yup.object().shape({
  [current_password.name]: Yup.string().required(current_password.errorMsg),
  [password.name]: Yup.string().required(password.errorMsg).min(8, password.invalidMsg),
  [password_confirmation.name]: Yup.string().required(password_confirmation.errorMsg).oneOf([Yup.ref("password")], password_confirmation.invalidMsg)
});

export default validations;
